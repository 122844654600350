import React, { FC } from 'react';
import { graphql, PageProps, Link } from 'gatsby';

import { Layout } from '../components/Layout';
import { CategoryData } from '../types';

interface DataProps {
    site: {
        siteMetadata: {
            title: string;
        }
    },
    allCategories: {
        nodes: CategoryData[];
    }
}

const Categories: FC<PageProps<DataProps>> = ({ data, location }) => {
    const { site, allCategories } = data;

    return (
        <Layout seoTitle='Список категорий' location={location}>
            <h1>Категории</h1>
            {allCategories.nodes.map(category => {
                const { name, slug, images_tales, text_tales } = category;

                return (
                    <li key={slug} style={{display: 'flex', flexDirection: 'column'}}>
                        <Link to={`/categories/${slug}`}>{name}</Link>
                        <small>Сказок в категории {[...text_tales, ...images_tales].length}</small>
                    </li>
                );
            })}
        </Layout>
    );
};

export default Categories;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allCategories {
            nodes {
              _id
              name
              slug
              images_tales {
                id
                name
                slug
              }
              text_tales {
                id
                name
                slug
              }
            }
          }
    }
`;
